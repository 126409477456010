<template>
	<div class="absolute w-full z-50">
		<div
			v-show="isMenuOpen"
			class="fixed w-screen h-screen bg-pine z-50 overflow-scroll"
		>
			<div class="mode-center">
				<div
					@click="closeMenu"
					class="py-8 flex justify-between items-center mb-20 mx640:mb-10"
				>
					<div>
						<router-link to="/"
							><img
								src="@/assets/footer/footer-logo.svg"
								alt=""
								class="mx640:w-30vw mx900:w-20vw"
						/></router-link>
					</div>
					<div @click="toggleMenu" class="mn640:hidden mr-3%">
						<img src="@/assets/cross.svg" alt="" />
					</div>
				</div>
				<div class="text-white pb-100">
					<p class="mb-3 text-2xl font-tiempos_r mx640:text-2xl mx900:text-2.5">
						{{ this.txt_what_we_do }}
					</p>
					<ul>
						<li @click="toggleMenu" v-for="(item, index) in items" :key="index">
							<FooterButton
								class="ml-8 mx900:text-2.5 mx640:text-2xl"
								:obj="item"
								:index="index"
							/>
						</li>
						<li
							@click="toggleMenu"
							v-for="(item, index) in items_2"
							:key="index"
						>
							<FooterButton
								class="mx900:text-2.5 mx640:text-2xl"
								:obj="item"
								:index="index"
							/>
						</li>
					</ul>
				</div>
			</div>
		</div>
		<div class="mode-center">
			<div class="py-8 flex justify-between items-center">
				<div class="relative">
					<router-link to="/">
						<img
							src="@/assets/nav-logo.svg"
							alt=""
							class="mx640:w-30vw mx900:w-20vw invisible"
						/>
					</router-link>
					<div class="pos-g z-10 pointer-events-none">
						<svg_nav />
					</div>
				</div>
				<div class="mn640:hidden flex items-center">
					<div class="relative inline-block">
						<div v-click-outside="hideLanguageMobileDropdown">
							<button
								type="button"
								@click="toggleLanguageMobile"
								class="inline-flex nav-btn text-pine hover:bg-microwave items-center text-base focus:outline-none mr-5"
								id="options-menu"
								aria-expanded="true"
								aria-haspopup="true"
							>
								{{ selectedLanguage.toUpperCase() }}
								<svg
									class="-mr-1 ml-2 h-5 w-5"
									xmlns="http://www.w3.org/2000/svg"
									viewBox="0 0 20 20"
									fill="currentColor"
									aria-hidden="true"
								>
									<path
										fill-rule="evenodd"
										d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
										clip-rule="evenodd"
									/>
								</svg>
							</button>
						</div>
						<div
							v-if="isLanguageMobileOpen"
							class="origin-top-right absolute right-0 mt-2 w-140 rounded-md shadow-lg bg-white ring-opacity-5 focus:outline-none"
							role="menu"
							aria-orientation="vertical"
							aria-labelledby="options-menu"
						>
							<div class="py-1 font-inter" role="none">
								<a
									v-for="(item, index) in languageOptins"
									:key="index"
									@click="handleLanguageChange(item.text)"
									class="block px-4 py-2  text-pine cursor-pointer hover:bg-gray-100 text-base transition duration-300 ease-in-out"
									role="menuitem"
									>{{ item.text }}</a
								>
							</div>
						</div>
					</div>
					<div @click="toggleMenu" class="mr-3%">
						<span class="w-6 h-1 bg-pine block rounded-sm mb-2"></span>
						<span class="w-6 h-1 bg-pine block rounded-sm"></span>
					</div>
				</div>
				<div class="text-crab mx640:hidden mx900:grid grid-cols-3">
					<div class="relative inline-block mx900:justify-self-end">
						<div v-click-outside="hideWhatWeDo">
							<button
								type="button"
								@click="toggleWhatWeDo"
								class="inline-flex nav-btn items-center focus:outline-none mx1280:text-1.5 mx900:text-sm mx900:mr-1"
								id="options-menu"
								aria-expanded="true"
								aria-haspopup="true"
							>
								{{ this.txt_what_we_do }}
								<svg
									class="-mr-1 ml-2 h-5 w-5"
									xmlns="http://www.w3.org/2000/svg"
									viewBox="0 0 20 20"
									fill="currentColor"
									aria-hidden="true"
								>
									<path
										fill-rule="evenodd"
										d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
										clip-rule="evenodd"
									/>
								</svg>
							</button>
						</div>
						<div
							v-if="isWhatWeDoOpen"
							class="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-opacity-5 focus:outline-none"
							role="menu"
							aria-orientation="vertical"
							aria-labelledby="options-menu"
						>
							<div class="py-1 font-inter" role="none">
								<router-link
									v-for="(item, index) in items"
									:key="index"
									:to="item.link"
									class="block px-4 py-2  text-crab hover:bg-gray-100 transition duration-300 ease-in-out"
									role="menuitem"
									>{{ item.text }}</router-link
								>
							</div>
						</div>
					</div>
					<nav_button
						v-for="(item, index) in items_2"
						:key="index"
						:obj="item"
					/>
					<div class="relative inline-block mx900:justify-self-end">
						<div v-click-outside="hideLanguageDropdown">
							<button
								type="button"
								@click="toggleLanguage"
								class="inline-flex nav-btn mr-0 text-pine hover:bg-microwave items-center focus:outline-none mx900:text-sm  mx1280:text-1.5 mx900:mr-1"
								id="options-menu"
								aria-expanded="true"
								aria-haspopup="true"
							>
								{{ selectedLanguage.toUpperCase() }}
								<svg
									class="-mr-1 ml-2 h-5 w-5"
									xmlns="http://www.w3.org/2000/svg"
									viewBox="0 0 20 20"
									fill="currentColor"
									aria-hidden="true"
								>
									<path
										fill-rule="evenodd"
										d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
										clip-rule="evenodd"
									/>
								</svg>
							</button>
						</div>
						<div
							v-if="isLanguageOpen"
							class="origin-top-right absolute right-0 mt-2 w-140 rounded-md shadow-lg bg-white ring-opacity-5 focus:outline-none"
							role="menu"
							aria-orientation="vertical"
							aria-labelledby="options-menu"
						>
							<div class="py-1 font-inter" role="none">
								<a
									v-for="(item, index) in languageOptins"
									:key="index"
									@click="handleLanguageChange(item.text)"
									class="block px-4 py-2  text-pine cursor-pointer hover:bg-gray-100 transition duration-300 ease-in-out"
									role="menuitem"
									>{{ item.text }}</a
								>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import nav_button from "@/components/ui/nav_button.vue";
	import ClickOutside from "vue-click-outside";
	import FooterButton from "@/components/ui/FooterButton";
	import svg_nav from "@/components/svg_nav";
	import { globals } from "@/modules/globals";
	import router from "../../router";

	import gsap from "gsap";

	export default {
		name: "nav_main",
		components: {
			nav_button,
			FooterButton,
			svg_nav,
		},
		directives: {
			ClickOutside,
		},
		data() {
			return {
				isMenuOpen: false,
				isWhatWeDoOpen: false,
				isLanguageOpen: false,
				isLanguageMobileOpen: false,
				selectedLanguage: globals.language,
				languageOptins: {
					en: {
						text: "EN",
					},
					nl: {
						text: "NL",
					},
				},
				items: {
					funds: {
						text: globals.navigation["fund-management"].title,
						link: "/" + globals.navigation["fund-management"].slug,
					},
					consulting: {
						text: globals.navigation["consulting"].title,
						link: "/" + globals.navigation["consulting"].slug,
					},
					community: {
						text: globals.navigation["community"].title,
						link: "/" + globals.navigation["community"].slug,
					},
				},
				items_2: {
					who_we_are: {
						text: globals.navigation["who-we-are"].title,
						link: "/" + globals.navigation["who-we-are"].slug,
					},
					news: {
						text: globals.navigation["news"].title,
						link: "/" + globals.navigation["news"].slug,
					},
					funds_2: {
						text: globals.navigation["funds"].title,
						link: "/" + globals.navigation["funds"].slug,
					},
					contact: {
						text: globals.navigation["contact"].title,
						link: "/" + globals.navigation["contact"].slug,
					},
				},
				txt_what_we_do: globals.navigation["what-we-do"].title,
			};
		},
		mounted() {},
		methods: {
			handleLanguageChange(selectedLanguage) {
				var lower = selectedLanguage.toLowerCase();
				if (lower == this.selectedLanguage) {
					return;
				}
				this.selectedLanguage = lower;
				var postfix = "/";
				if (selectedLanguage == "EN") {
					postfix = "/en/";
				}
				let curRoute = this.$router.currentRoute.path;
				let page_routing = curRoute.split("/");
				let crumbs = page_routing.filter(function(x) {
					return x !== "";
				});
				let shadowPath = postfix;
				if (crumbs.length > 0) {
					crumbs.forEach(function(item, index) {
						shadowPath += globals.slugs_translations[item] + "/";
					});
				}
				var lang_url =
					window.location.protocol + "//" + window.location.host + shadowPath;
				//var lang_url = shadowPath
				console.log(lang_url);
				window.location.href = lang_url;

				/*
				 */

				/*
				window.localStorage.setItem("language", lower);
				window.location.href = "/";
				*/
			},
			toggleWhatWeDo() {
				this.isWhatWeDoOpen = !this.isWhatWeDoOpen;
			},
			hideWhatWeDo() {
				this.isWhatWeDoOpen = false;
			},
			hideLanguageDropdown() {
				this.isLanguageOpen = false;
			},
			hideLanguageMobileDropdown() {
				gsap.delayedCall(0.2, this.hideLanguageMobileDropdownn)
			},
			hideLanguageMobileDropdownn() {
				this.isLanguageMobileOpen = false;
			},
			toggleLanguage() {
				this.isLanguageOpen = !this.isLanguageOpen;
			},
			toggleLanguageMobile() {
				this.isLanguageMobileOpen = !this.isLanguageMobileOpen;
			},
			toggleMenu() {
				this.isMenuOpen = !this.isMenuOpen;
				this.$emit("menuChangeStatus", this.isMenuOpen);
			},
			closeMenu() {
				this.isMenuOpen = false;
			},
		},
	};
</script>

<style></style>
