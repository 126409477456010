<template>
	<div class="">
		<div class="bg-pine">
			<div
				class="text-white grid grid-cols-4 gap-10 mode-center py-65 mx1080:grid-cols-3 mx640:grid-cols-1"
			>
				<div class="col-span-2 font-inter">
					<div class="mb-8">
						<router-link to="/"
							><img src="@/assets/footer/footer-logo.svg" alt=""
						/></router-link>
					</div>
					<div v-if="contact_info" class="">
						<div
							class="rich-text contact-rich-text"
							v-html="contact_info"
						></div>
					</div>
				</div>
				<div class="flex flex-col col-span-1">
					<p class="mb-3 text-2xl font-tiempos_r mx640:text-2xl mx900:text-2.5">
						{{ txt_what_we_do }}
					</p>
					<ul>
						<li
							class="anime-hover-mandarin"
							v-for="(item, index) in what_we_do"
							:key="index"
						>
							<FooterButton
								class="ml-8 mx900:text-2.5 mx640:text-2xl"
								:obj="item"
								:index="index"
							/>
						</li>
						<li
							class="anime-hover-mandarin"
							v-for="(item, index) in items"
							:key="index"
						>
							<FooterButton
								class="mx900:text-2.5 mx640:text-2xl"
								:obj="item"
								:index="index"
							/>
						</li>
					</ul>
				</div>
				<div class="col-span-1 mx1080:col-span-2">
					<div class="text-mandarin font-inter pb-10">
						{{ hdr_newsletter }}
					</div>
					<div class="form w-full inline-block text-xl">
						<form
							action="https://group.us16.list-manage.com/subscribe/post?u=22a9f69c2dcb7792e81e1c639&amp;id=8df76ccc64"
							method="post"
							id="mc-embedded-subscribe-form"
							name="mc-embedded-subscribe-form"
							target="_blank"
							novalidate
							class="flex justify-between border-b border-metal"
						>
							<input
								type="email"
								value=""
								name="EMAIL"
								id="mce-EMAIL"
								class="py-4 text-mandarin font-inter focus:outline-none w-full bg-pine placeholder-metal focus:placeholder-opacity-0"
								:placeholder="email_newsletter"
							/>
							<button
								type="submit"
								name="subscribe"
								id="mc-embedded-subscribe"
								class="text-mandarin nav-btn mr-0 font-inter focus:outline-none"
							>
								{{ btn_newsletter }}
							</button>
						</form>
					</div>
				</div>
			</div>
		</div>
		<div class="bg-microwave py-3 font-inter">
			<div class="grid grid-cols-2 mode-center mx640:grid-cols-1 mx640:gap-6">
				<div class="text-white">
					© Shaping Impact Group
				</div>
				<div class="text-stellar">
					<a
						:href="subfooter.privacy.link"
						class="mr-12 transition duration-500 ease-in-out hover:text-white mx480:block"
						>{{ subfooter.privacy.text }}</a
					>

					<a
						:href="subfooter.cookie.link"
						class="mr-12 transition duration-500 ease-in-out hover:text-white mx480:block"
						>{{ subfooter.cookie.text }}</a
					>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import FooterButton from "@/components/ui/FooterButton.vue";
	import { globals } from "@/modules/globals";
	export default {
		name: "Footer",
		components: { FooterButton },
		data() {
			return {
				hdr_newsletter: globals.microcopy.hdr_newsletter[globals.language],
				email_newsletter: globals.microcopy.email_newsletter[globals.language],
				btn_newsletter: globals.microcopy.btn_newsletter[globals.language],
				contact_info: globals.segments["contact-info"].content,
				what_we_do: {
					funds: {
						text: globals.navigation["fund-management"].title,
						link: "/" + globals.navigation["fund-management"].slug,
					},
					consulting: {
						text: globals.navigation["consulting"].title,
						link: "/" + globals.navigation["consulting"].slug,
					},
					community: {
						text: globals.navigation["community"].title,
						link: "/" + globals.navigation["community"].slug,
					},
				},
				items: {
					who_we_are: {
						text: globals.navigation["who-we-are"].title,
						link: "/" + globals.navigation["who-we-are"].slug,
					},
					news: {
						text: globals.navigation["news"].title,
						link: "/" + globals.navigation["news"].slug,
					},
					funds_2: {
						text: globals.navigation["funds"].title,
						link: "/" + globals.navigation["funds"].slug,
					},
					contact: {
						text: globals.navigation["contact"].title,
						link: "/" + globals.navigation["contact"].slug,
					},
				},
				txt_what_we_do: globals.navigation["what-we-do"].title,
				subfooter: {
					privacy: {
						text: globals.navigation["privacy-statement"].title,
						link: "/" + globals.navigation["privacy-statement"].slug,
					},
					cookie: {
						text: globals.navigation["cookie-policy"].title,
						link: "/" + globals.navigation["cookie-policy"].slug,
					},
				},
			};
		},
		mounted() {},
	};
</script>

<style></style>
