import Vue from "vue";
import VueRouter from "vue-router";
import { globals } from "@/modules/globals";
import { EventBus } from "@/modules/event_bus";

Vue.use(VueRouter);
const routes = [
	{
		path: "/",
		name: "home",
		component: () => import(/* webpackChunkName: "home" */ "@/views/home.vue"),
	},
	{
		path: "*",
		name: "page_404",
		component: () =>
			import(/* webpackChunkName: "main" */ "@/components/page_404.vue"),
	},
];

const comps = {
	home: {
		usable: true,
		component: () => import(/* webpackChunkName: "home" */ "@/views/home.vue"),
		multi: false,
	},
	"what-we-do": {
		usable: false,
	},
	"fund-management": {
		usable: true,
		component: () =>
			import(/* webpackChunkName: "fund-management" */ "../views/Funds.vue"),
		multi: false,
	},
	consulting: {
		usable: true,
		component: () =>
			import(/* webpackChunkName: "consulting" */ "../views/Consulting.vue"),
		multi: false,
	},
	community: {
		usable: true,
		component: () =>
			import(/* webpackChunkName: "community" */ "../views/Community.vue"),
		multi: false,
	},
	contact: {
		usable: true,
		component: () =>
			import(/* webpackChunkName: "contact" */ "../views/Contact.vue"),
		multi: false,
	},
	funds: {
		usable: true,
		component: () =>
			import(/* webpackChunkName: "funds" */ "../views/Funds_v2.vue"),
		multi: true,
		multi_component: () =>
			import(/* webpackChunkName: "funds" */ "../views/FundSingle.vue"),
	},
	"who-we-are": {
		usable: true,
		component: () => import(/* webpackChunkName: "team" */ "../views/Team.vue"),
		multi: true,
		multi_component: () =>
			import(/* webpackChunkName: "team" */ "../views/TeamSingle.vue"),
	},
	news: {
		usable: true,
		component: () =>
			import(/* webpackChunkName: "news" */ "../views/NewsPage.vue"),
		multi: true,
		multi_component: () =>
			import(/* webpackChunkName: "news" */ "../views/NewsSingle.vue"),
	},
	'privacy-statement': {
		usable: true,
		component: () =>
			import(/* webpackChunkName: "main" */ "../views/Privacy.vue"),
		multi: false,
	},
	'cookie-policy': {
		usable: true,
		component: () =>
			import(/* webpackChunkName: "main" */ "../views/Cookie.vue"),
		multi: false,
	},
};

import gsap from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
gsap.registerPlugin(ScrollToPlugin);
gsap.config({ nullTargetWarn: false });

var lv = {}

var popped = false;
var scrollPositions = {};

var baseRoute = "";
if (window.language == "en") {
	baseRoute = "/en/";
}
/*
if (window.localStorage.getItem("language") == "en") {
	baseRoute = "/en/";
	globals.language = "en";
}else globals.language = "nl";
*/
//console.log(baseRoute)

const router = new VueRouter({
	mode: "history",
	//base: process.env.BASE_URL,
	base: baseRoute,
	routes: routes,
	scrollBehavior(to, from) {
		if (to.query.topic) {
			return;
		}
		var pageY = 0;
		if (scrollPositions[to.name]) {
			if (popped) {
				pageY = scrollPositions[to.name];
			}
		}
		popped = false;
		gsap.to(window, { duration: 0.4, scrollTo: pageY, ease: "power2.inOut" });
		//return { x: 0, y: pageY }
	},
});


EventBus.$on("app_ready", (e) => {
	for (const prop in globals.navigation) {
		//console.log(prop)
		//console.log(globals.navigation[prop])

		if (prop != "home") {
			if (comps[prop].usable) {
				router.addRoute({
					path: "/" + globals.navigation[prop].slug,
					name: globals.navigation[prop].title,
					component: comps[prop].component,
				});
				if (comps[prop].multi) {
					router.addRoute({
						path: "/" + globals.navigation[prop].slug + "/:slug",
						name: globals.navigation[prop].title + "_single",
						component: comps[prop].multi_component,
					});
				}
			}
		}
	}
});

router.beforeEach((to, from, next) => {
	scrollPositions[from.name] = window.pageYOffset;
	gsap.to("#logo_sun_icon", {
		duration: 1.4,
		rotation: "+=90",
		svgOrigin: "46px 46px",
		ease: "power4.out",
	});
	gsap.to("#curtain_main", {
		duration: 0.5,
		display: "block",
		opacity: 1,
		ease: "power3.inOut",
		onComplete: function() {
			next();
		},
	});

	//console.log('page title')
	//console.log(to)
	lv.slug_parts = to.fullPath.split("/")
	//console.log(lv.slug_parts)

	lv.slug_index = 1

	Object.keys(globals.navigation).forEach((key) =>{
		if(globals.navigation[key]['slug'] == lv.slug_parts[lv.slug_index]){
			document.title = globals.navigation[key]['title']
		}
	})
	if(to.name == 'home'){
		document.title = 'Shaping Impact Group'
	}
});

router.afterEach((to, from) => {
	gsap.to("#curtain_main", {
		delay: 0.2,
		duration: 0.5,
		display: "none",
		opacity: 0,
		ease: "power3.inOut",
	});
});

window.addEventListener("popstate", () => {
	popped = true;
});

export default router;
