<template>
	<router-link :to="obj.link" class="footer-btn">
		{{ obj.text }}
	</router-link>
</template>

<script>
	export default {
		name: "FooterButton",
		props: ["obj"],
		data() {
			return {};
		},
		mounted() {},
	};
</script>

<style></style>
