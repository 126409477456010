<template>
	<router-link
		:to="obj.link"
		class="nav-btn last:mr-0 mx1280:text-1.5 mx900:text-sm mx900:mr-1 mx900:justify-self-end"
		>{{ obj.text }}</router-link
	>
</template>

<script>
	export default {
		name: "nav_button",
		props: ["obj"],
		data() {
			return {};
		},
		mounted() {},
	};
</script>

<style></style>
