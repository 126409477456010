<template>
  <div id="app" :class="{ menu_mobile_open: isMenuOpen }">

    <!-- @tijmes hier even allen voor test-->
     <!--  
    <div v-if="isBannerShow" class="coockie_wrapper">
      <div class="cookie_bg"></div>
      <div class="cookie">
        <h1>Are you ok with our cookie policy?</h1>
        <button @click="opt_in">Yes</button>
        <button @click="opt_out" style="background:rgb(173, 54, 54);">
          No
        </button>
      </div>
    </div>
   -->

    <nav_main @menuChangeStatus="handleMenuOpen" />

    <router-view />

    <Footer />


    <div class="consent_wrap z-10" id="cookie_consent" v-if="accept_cookies">
      <div class="relative bg-white p-4 rounded-md border border-mandarin border-opacity-50 shadow-md font-inter anime-hover">
        <div class="hidden" id="consent_text_nl">
          Deze website gebruikt cookies. Ze zorgen ervoor dat de website goed kan functioneren, en verzamelen informatie over het gebruik van onze website zodat we die kunnen optimaliseren.<br/> 
          Door hieronder op "OK" te klikken, gaat u akkoord met de plaatsing en het gebruik van onze cookies.<br/>
          Lees er meer over in onze <a href="/cookie-beleid">cookie policy</a>.<br/>
          <div class="flex pt-4 text-lg">
            <a href="#ok" class="py-1 px-2 pr-3" @click.prevent.stop="cookie_accept()">OK</a>
            <div class="ml-auto"></div>
            <a href="#ok" class="py-1 px-2" @click.prevent.stop="cookie_deny()">weigeren</a>
          </div>
        </div>
        <div class="hidden" id="consent_text_en">
          We use cookies to collect information about your preferences to improve the performance of our website and collect information about the use of our website to improve the experience of our visitors.<br/>
          By clicking on "OK" below, you agree to the placement and use of our cookies.
          For more information, please consult our <a href="/cookie-policy">cookie policy</a>.
          <div class="flex pt-4 text-lg">
            <a href="#ok" class="py-1 px-2 pr-3" @click.prevent.stop="cookie_accept()">OK</a>
            <div class="ml-auto"></div>
            <a href="#ok" class="py-1 px-2" @click.prevent.stop="cookie_deny()">refuse</a>
          </div>
        </div>
      </div>
    </div>

    <div class="pos-g fixed bg-white z-10" id="curtain_main"></div>
  </div>
</template>

<script>
  import globals from "@/modules/globals";
  import { EventBus } from "@/modules/event_bus";
  import control_main from "@/modules/control_main";
  import * as cheet from 'cheet.js'

  import { bootstrap } from 'vue-gtag'

  import gsap from "gsap";

  import nav_main from "@/components/ui/nav_main.vue";
  import Footer from "@/components/ui/Footer.vue";

  export default {
    name: "app",
    components: {
      nav_main,
      Footer,
    },
    data() {
      return {
        isMenuOpen: false,
        isBannerShow: false,
        accept_cookies: true
      };
    },
    mounted() {
      this.consent_text = this['consent_text_' + globals.language]

      const targ = '#consent_text_' + globals.language
      gsap.set(targ,{display:'block'})

      if (localStorage.consent == 1 || localStorage.consent == 0) {
        this.accept_cookies = false
      }
      if (localStorage.consent == 1) {
        this.gtag_enable()
      }

      /*
      // cookie test

      cheet('o p t i n', () => {

          bootstrap().then((gtag) => {
            console.log('gtag activated')
          })        
          console.log('optin')
      })

      cheet('o p t o u t', () => {
          console.log('optout')
      })

      console.log('gtag--------')
      //console.log(this.$gtag)
      //this.$gtag.optIn()

      setTimeout(() => {
        this.isBannerShow = true;
      }, 3000);
      */

      // hijack a href to router
      window.addEventListener("click", (event) => {
        let { target } = event;
        while (target && target.tagName !== "A") target = target.parentNode;
        if (target && target.matches("a:not([href*='://'])") && target.href) {
          if (
            target &&
            target.matches("a:not([href*='mailto:'])") &&
            target.href
          ) {
            const {
              altKey,
              ctrlKey,
              metaKey,
              shiftKey,
              button,
              defaultPrevented,
            } = event;
            if (metaKey || altKey || ctrlKey || shiftKey) return;
            if (defaultPrevented) return;
            if (button !== undefined && button !== 0) return;
            if (target && target.getAttribute) {
              const linkTarget = target.getAttribute("target");
              if (/\b_blank\b/i.test(linkTarget)) return;
            }
            const url = new URL(target.href);
            const to = url.pathname;
            if (window.location.pathname !== to && event.preventDefault) {
              event.preventDefault();
              this.$router.push(to);
            }
          }
        }
      });
    },
    updated: function() {
      this.$nextTick(function() {
        let domdata = {};
        domdata["fullslug"] = this.$router.currentRoute.path;
        domdata["dom"] = document.getElementById("app").innerHTML;
        EventBus.$emit("dom_rendered", domdata);
      })
    },
    methods: {
      // hier kan je analytics gemakkelijk uit een aan zetten
      opt_out() {
        this.$gtag.optOut();
        this.isBannerShow = false;
      },
      opt_in() {
        this.$gtag.optIn();
        this.isBannerShow = false;
      },
      cookie_accept(){
        this.gtag_enable()
        localStorage.consent = 1 
        this.cookie_consent_hide()
        // hide gdpr banner
      },
      cookie_deny(){
        localStorage.consent = 0
        this.cookie_consent_hide()
        // hide gdpr banner
      },
      cookie_consent_hide(){
        gsap.to('#cookie_consent',{opacity:0, display:'none', ease:'power2.inOut'})
      },
      gtag_enable(){
        bootstrap().then((gtag) => {
          //console.log('gtag activated')
          this.$gtag.optIn()
        })        
      },
      handleMenuOpen() {
        this.isMenuOpen = !this.isMenuOpen;
      },
      appear(el, done) {
        //gsap.to('#curtain_main',{duration:0.5, display: 'none', opacity: 0, ease:'power3.inOut', onComplete: done })
      },
      enter(el, done) {
        //gsap.to('#curtain_main',{delay:0.2, duration: 0.5, display: 'none', opacity: 0, ease:'power3.inOut', onComplete: done })
      },
      leave(el, done) {
        //gsap.to('#logo_sun_icon',{duration:1, rotation: '+=90', svgOrigin:"46px 46px", ease:'bounce' })
        //gsap.to('#curtain_main',{duration:0.5, display: 'block', opacity: 1, ease:'power3.inOut', onComplete: done })
      },
    },
  };
</script>

<style>
  .menu_mobile_open {
    position: fixed;
  }

  /* hier ook css voor analytics test  */
  .cookie_bg {
    background: black;
    opacity: 0.7;
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    z-index: 99;
  }
  .cookie {
    background: white;
    color: rgb(7, 77, 77);
    padding: 50px;
    font-size: 40px;
    width: 90%;
    height: 250px;
    border-radius: 5px;
    margin: 0 auto;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 1;
    z-index: 100;
  }
  .cookie button {
    font-size: 20px;
    background: rgb(63, 205, 162);
    color: rgb(2, 15, 129);
    border-radius: 5px;
    margin: 20px;
    padding: 10px 20px;
  }
  .cookie button:hover {
    color: white;
  }

  .consent_wrap{
    position: fixed;
    bottom: 0px;
    right: 0px;
    padding: 15px;

    max-width: 350px;
    font-size: 15px;
  }
</style>
