export var globals = {
	languages: ["en", "nl"],
	language: "nl",
	text: {
		text_404: {
			en: "Sorry, this page doesn't exist (anymore?)",
			nl: "Sorry, deze pagina bestaat niet (meer?)",
		},
	},
	consolify: false,
	content_version: 1,
};

export default globals;
