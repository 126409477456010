import Vue from "vue";
import app from "./app.vue";
import router from "./router";
import VueFormulate from "@braid/vue-formulate";
import "@/assets/styles/main.css";
import "@/assets/styles/tailwind.css";

import VueGlide from "vue-glide-js";
import "vue-glide-js/dist/vue-glide.css";

import { globals } from "@/modules/globals";
import { EventBus } from "@/modules/event_bus";

// sigTest (sig.multitudemo.nl) > G-8XRP9ZRS1C
// SIG Universal > UA-203971694-1
// SIG G4 > G-Z58TSQGCCC

import VueGtag from "vue-gtag";
Vue.use(VueGtag, {
  config: { 
    // id: 'G-XXXXXXXXX',
    // id: 'G-8XRP9ZRS1C',
    id: 'UA-203971694-1',
    params: {
      anonymize_ip: true
    }
  },
  includes: [
    { 
      id: 'G-Z58TSQGCCC',
      params: {
        anonymize_ip: true
      }
    },
  ],
  enabled: true,
  bootstrap: false,
  onReady () {
    console.log('gtag-ready')
  },
  onBeforeTrack () {
    console.log('gtag-track-start')
  },
  onAfterTrack () {
    console.log('gtag-track-end')
  }
}, router)

Vue.use(VueGlide);

if (window.language == "en") {
  globals.language = "en";
}
globals.content_version = Math.floor(Math.random() * 1000) + 1;

import { en, nl } from "@braid/vue-formulate-i18n";
Vue.use(VueFormulate, {
  plugins: [en, nl],
  locale: globals.language,
});

if (process.env.NODE_ENV === "production") {
  console.log(
    "%c Shaping Impact Group :: by multitude.nl ",
    "background: #000000; color: #fff; padding:3px; font-size:16px;"
  );
}

Vue.config.productionTip = false;

function app_start() {
  new Vue({
    router,
    render: (h) => h(app),
  }).$mount("#app");
}

let json_file_contentVersion =
  "/json/content_version.json?v=" + globals.content_version;
fetch(json_file_contentVersion)
  .then((response) => response.json())
  .then(function(data) {
    //console.log(data.version)
    globals.content_version = data.version;
    loadJsons();
  })
  .catch((error) => {
    console.error("FETCH Error:", error);
  });

let dataFiles = 4;
let microcopyData = null;
let pagesData = null;
let slugs_translations = null;
let segmentsData = null;

function loadJsons() {
  let json_file_microcopy =
    "/json/microcopy/microcopy_" +
    globals.language +
    ".json?v=" +
    globals.content_version;
  fetch(json_file_microcopy)
    .then((response) => response.json())
    .then(function(data) {
      microcopyData = data;
      afterJsonLoaded();
    })
    .catch((error) => {
      console.error("FETCH Error:", error);
    });
  let json_file_pages =
    "/json/pages/pages_" +
    globals.language +
    ".json?v=" +
    globals.content_version;
  fetch(json_file_pages)
    .then((response) => response.json())
    .then(function(data) {
      pagesData = data;
      afterJsonLoaded();
    })
    .catch((error) => {
      console.error("FETCH Error:", error);
    });
  let json_file_shadowPages =
    "/json/slugs_translations/slugs_translations_" +
    globals.language +
    ".json?v=" +
    globals.content_version;
  fetch(json_file_shadowPages)
    .then((response) => response.json())
    .then(function(data) {
      slugs_translations = data;
      afterJsonLoaded();
    })
    .catch((error) => {
      console.error("FETCH Error:", error);
    });
  let json_file_segments =
    "/json/segments/segments_" +
    globals.language +
    ".json?v=" +
    globals.content_version;
  fetch(json_file_segments)
    .then((response) => response.json())
    .then(function(data) {
      segmentsData = data;
      afterJsonLoaded();
    })
    .catch((error) => {
      console.error("FETCH Error:", error);
    });
}

let countLoaded = 0;
function afterJsonLoaded() {
  countLoaded++;
  if (countLoaded == dataFiles) {
    globals.microcopy = microcopyData;
    globals.navigation = pagesData;
    globals.segments = segmentsData;

    globals.slugs_translations = slugs_translations;

    EventBus.$emit("app_ready");

    app_start();
  }
}

/*

import { EventBus } from "./modules/event_bus"
import control_main from './modules/control_main'
import globals from './modules/globals'

if(localStorage.getItem('consolify')){
  console.log('consolify active')
  globals.consolify = true
}

control_main.connect_first()


*/
