
import * as cheet from 'cheet.js'
import { EventBus } from './event_bus'
import globals from './globals'
import api from './api'
import axios from 'axios'
import sha256 from 'crypto-js/sha256'


var lv = {}
lv.keysource = 'd8heyrgftyr6775ytugh5y4h8u5j994e5e4d5f4t55njf984yg4h55y4hu5j4i8632k1i878uoooplw2841'

let control_main = {
    connect_first(){
        lv.task = 'first_contact'
        lv.event = 'data_first_contact'
        api.call({
            task: lv.task,
            event: lv.event,
            global: false
        })
        EventBus.$on(lv.event, (e) => {
            control_main.connect_first_done(e)
        })
    },
    connect_first_done(d){
        if(d.result == 'error'){
            control_main.console_log('sorry, no connection')
            return
        }
        if(d.result == 'ok'){
            control_main.console_log('have connection')
            if(d.data.data.result_preview == 'cookie_found'){
                control_main.console_log('preview is allowed')
                globals.preview_allowed = true
            }
        }
        globals.connection_established = true
        EventBus.$emit('connect_first_success')
    },
    preloader_done(){
        EventBus.$emit('preloader_done')
    },
    console_log(msg){
        lv.golog = false
        if(process.env.NODE_ENV !== 'production'){
            lv.golog = true
        }
        if(globals.consolify){
            lv.golog = true
        }
        if(lv.golog){
            console.log(msg)
        }
    },

    makeKey(str) {
        lv.keysplit = lv.keysource.split('55')
        lv.sha = sha256(lv.keysplit[1] + str).toString()
     },
      
    dom(e){

        lv.slug_index = 1
        if(globals.language == 'en'){
            lv.slug_index = 2
        }

        lv.seo_data = {}
        lv.slug_parts = e["fullslug"].split("/")
        //console.log('dom meta -----')
        //console.log(e["fullslug"])
        //console.log(lv.slug_parts)

        if(lv.slug_parts[lv.slug_index] == ''){
            lv.slug_parts[lv.slug_index] = 'home'
        }

        Object.keys(globals.navigation).forEach((key) =>{
            if(globals.navigation[key]['slug'] == lv.slug_parts[lv.slug_index]){
                //console.log('...found you...')
                if(globals.navigation[key]['seo_title']){
                    lv.seo_data['seo_title'] = globals.navigation[key]['seo_title']
                }
                if(globals.navigation[key]['seo_description']){
                    lv.seo_data['seo_description'] = globals.navigation[key]['seo_description']
                }
                if(globals.navigation[key]['seo_image']){
                    lv.seo_data['seo_image'] = globals.navigation[key]['seo_image']['file']
                }
            }
        })

        if(globals.news_item_cur){
            //console.log('>>>>found news sub page item')
            //console.log(globals.news_item_cur)
            if(globals.news_item_cur['title']){
                lv.seo_data['seo_title'] = globals.news_item_cur['title']
            }
            if(globals.news_item_cur['summary']){
                lv.seo_data['seo_description'] = globals.news_item_cur['summary']
            }
            if(globals.news_item_cur['news_image']){
                lv.seo_data['seo_image'] = globals.news_item_cur['news_image']['file']
            }
            delete globals.news_item_cur
        }

        if(globals.funds_item_cur){
            //console.log('>>>>found funds sub page item')
            //console.log(globals.funds_item_cur)
            if(globals.funds_item_cur['title']){
                lv.seo_data['seo_title'] = globals.funds_item_cur['title']
            }
            if(globals.funds_item_cur['description']){
                lv.seo_data['seo_description'] = globals.funds_item_cur['description']
            }
            if(globals.funds_item_cur['image']){
                lv.seo_data['seo_image'] = globals.funds_item_cur['image']['file']
            }
            delete globals.funds_item_cur
        }
        //console.log('________seo_data________')
        //console.log(lv.seo_data)
       
        e.seo = lv.seo_data

        if(process.env.NODE_ENV !== 'production'){
            return
        }
        lv.data = JSON.stringify(e)
        lv.key = control_main.makeKey(lv.data)

        lv.url_pre = ''
        //lv.url_pre = 'http://localhost'
        lv.url_dpi = lv.url_pre + '/dpi/dom.php'

        axios.post(lv.url_dpi, {
            lang: globals.language,
            data: lv.data,
            key: lv.sha,
            content_version: globals.content_version,
         }, { withCredentials: false })
         .then(function (response) {
            //control_main.console_log(response.data)
         })
    }
}

EventBus.$on('dom_rendered', (e) => {
	control_main.dom(e)
})

cheet('g l o b a l s', function () {
    console.log(globals)
})
 
cheet('c o n s o l i f y', function () {
    if(localStorage.getItem('consolify')){
        console.log('got consolify, removing it')
        globals.consolify = false
        localStorage.removeItem('consolify')
    }else{
        console.log('havent got consolify, setting it')
        globals.consolify = true
        localStorage.setItem('consolify', true)
    }
})
  
  
export default control_main
